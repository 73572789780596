import { Injectable, inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from '../../shared/api/api.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  #apiService = inject(ApiService);
  #msalService = inject(MsalService);

  isAuthenticated() {
    return this.#msalService.instance.getActiveAccount() ? true : false;
  }

  getToken() {
    const officeUser = JSON.parse(localStorage.getItem('O365') || 'null');
    // console.log(officeUser?.token);

    return officeUser?.token;
  }

  // isTokenExpired() {
  //   const token = this.getToken();
  //   if (!token) return true;
  //   return this.#jwtService.isTokenExpired(token);
  // }

  logout() {
    this.#msalService.logout();
    return localStorage.clear();
  }

  getPermissionOfUser(usernameId?:string) {
    return this.#apiService.callApi(
      '/auth/permissions/v1/users',
      'get'
    );
  }

  // resetPasswordWithToken(
  //   payload: components['schemas']['ResetPasswordTokenDto']
  // ) {
  //   return this.#apiService.callApi(
  //     '/employee_user/v1/resetPasswordWithToken',
  //     'post',
  //     { content: { 'application/json': payload } }
  //   );
  // }

  // fetchRole() {
  //   return this.#apiService.callApi('/role/v1', 'get');
  // }
}
