import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export function noWhitespaceValidator(control: FormControl) {
  const isSpace = (control.value || '').match(/^\s+$/); //changing regex from /^\s*$/ to /^\s+$/ for excluding empty control value
  return isSpace ? { whitespace: true } : null;
}

export function requiredValidator(control: FormControl) {
  const isNull = !control.value;
  return isNull ? { required: true } : null;
}

export function hasNumberValidator(control: FormControl) {
  if (!control.value) return null;
  const minEight = /.{8,30}/.test(control.value);
  const hasNumber = /\d/.test(control.value);
  const hasSpCharacters = /[!@#$]/.test(control.value);
  const hasCapital = /[A-Z]+/.test(control.value);
  console.log(hasNumber);

  if (!minEight) return { notMinLength: true };
  if (!hasNumber) return { notNumber: true };
  if (!hasCapital) return { noCapital: true };
  if (!hasSpCharacters) return { noSpCharacters: true };

  return null;
}

export function confirmPasswordGroupValidator(group: FormGroup) {
  const password = group.get('newPassword');
  const confPassword = group.get('confirmedPassword');

  return password?.value === confPassword?.value ? null : { notmatched: true };
}

/**
 * validates the form controls depending on another control.
 * if the first control and the second control has no value or they both have values. Then the group is valid.
 * if one of the has no value.. then the group is invalid
 * @param firstControlName
 * @param secondControlName
 * @returns
 */
export function crossFieldValidator(
  firstControlName: string,
  secondControlName: string
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const firstControl = control.get(firstControlName);
    const secondControl = control.get(secondControlName);

    if (
      (!firstControl?.value && !secondControl?.value) ||
      (firstControl?.value && secondControl?.value)
    ) {
      return null;
    }

    if (firstControl?.value && !secondControl?.value) {
      secondControl?.setValidators([Validators.required]);
    } else {
      secondControl?.clearValidators();
    }

    if (secondControl?.value && !firstControl?.value) {
      firstControl?.setValidators([Validators.required]);
    } else {
      firstControl?.clearValidators();
    }

    firstControl?.updateValueAndValidity({ onlySelf: true });
    secondControl?.updateValueAndValidity({ onlySelf: true });

    return null;
  };
}

/**
 * Returns TRUE if the first specified array contains all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
export function arrayContainsArray(superset: any[], subset: any[]) {
  if (0 === subset.length) {
    return false;
  }
  return subset.every(function (value) {
    return superset.indexOf(value) >= 0;
  });
}
/**
 * Returns TRUE if the first specified array contains all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
export function arrayContainsArrayPartial(superset: any[], subset: any[]) {
  if (0 === subset.length) {
    return false;
  }
  return subset.some(function (value) {
    return superset.indexOf(value) >= 0;
  });
}
